<template>
  <div class="home-wrapper" :class="{mobilePage : isMobile}">
    <web-header :disabledScroll="true" />
    <div class="mainImg">
      <img class="bannerImgCut" src="@/assets/style/cover.png" alt="" />
    </div>
    <!-- 第一个功能 -->
    <div class="functionItem imgRight">
            <div class="functionImg" v-if="isMobile">
        <img class="imgCut" src="@/assets/style/Group 72.png" alt="" />
      </div>
      <div class="functionText">
        <div class="functionTitle">
          <div class="cirleIcon"></div>
          <div class="titleText">纯粹的电子音乐社区</div>
        </div>
        <div class="secondContentText">
          <div class="funcSubtitle">量身定制话题频段分类 小圈子也有大舞台</div>
          <div class="describtion">
            -
            多频社区针对电子音乐圈子特点量身打造超全超细致话题频段分类。风格或艺人，流行或地下，听歌或制作，未来或复古，你总能找到适合你的频段；<br />
            - 300多种细分风格独立频段，品味相投的小伙伴都在这儿
          </div>
        </div>
      </div>
      <div class="functionImg" v-if="!isMobile">
        <img class="imgCut" src="@/assets/style/Group 72.png" alt="" />
      </div>
    </div>
    <!-- 第二个功能 -->
    <div class="functionItem imgleft">
      <div class="functionImg">
        <img class="imgCut" src="@/assets/style/Group 73.png" alt="" />
      </div>
      <div class="functionText">
        <div class="functionTitle">
          <div class="cirleIcon"></div>
          <div class="titleText">多频电音百科</div>
        </div>
        <div class="secondContentText">
          <div class="funcSubtitle">
            风格、厂牌、艺人、现场...科普知识应有尽有
          </div>
          <div class="describtion">
            -
            多频社区致力于搭建公开共享的电子音乐百科资料库，收录与电音相关的各类信息资料以供查阅。从此以后，再也不用担心上哪儿了解电音知识了！
            <br />
            -
            多频电音百科，现收录电音风格详细资料370+，艺人资料30000+，电音节资料200+，厂牌资料500+，夜店/Live
            house资料500+，还有其他各类电音软硬件设备及产品资料等你来发掘，合计翻译编辑汉字内容超50万字！
          </div>
        </div>
      </div>
    </div>
    <!-- 第三个功能 -->
    <div class="functionItem imgRight">
            <div class="functionImg" v-if="isMobile">
        <img class="imgCut" src="@/assets/style/3Body 1.png" alt="" />
      </div>
      <div class="functionText">
        <div class="functionTitle">
          <div class="cirleIcon"></div>
          <div class="titleText">新鲜圈内资讯</div>
        </div>
        <div class="secondContentText">
          <div class="funcSubtitle">海内外电音圈咨讯动态一手掌握</div>
          <div class="describtion">
            -
            多频社区网罗每日全球各地电子音乐新闻资讯，无论是艺人动态、新歌发布，还是演出活动，行业发展，一站知晓电音圈内大小事
          </div>
        </div>
      </div>
      <div class="functionImg" v-if="!isMobile">
        <img class="imgCut" src="@/assets/style/3Body 1.png" alt="" />
      </div>
    </div>
    <!-- 第四个功能 -->
    <div class="functionItem imgleft">
      <div class="functionImg">
        <img class="imgCut" src="@/assets/style/13body 1.png" alt="" />
      </div>
      <div class="functionText">
        <div class="functionTitle">
          <div class="cirleIcon"></div>
          <div class="titleText">丰富内容形式</div>
        </div>
        <div class="secondContentText">
          <div class="funcSubtitle">图文影音超链接，创作分享更便捷</div>
          <div class="describtion">
            -
            在多频社区发布信号内容，向广大电子音乐爱好者分享你对电音的理解和观点；
            <br />
            -
            你将可以上传文字、图片，以及分享音乐、视频、超链接等，便捷排版，轻松创作充满吸引力的信号内容，收获满满的点头肯定哦！
          </div>
        </div>
      </div>
    </div>
    <!-- 第五个功能 -->
    <div class="functionItem imgRight">
            <div class="functionImg" v-if="isMobile">
        <img class="imgCut" src="@/assets/style/Group 74.png" alt="" />
      </div>
      <div class="functionText">
        <div class="functionTitle">
          <div class="cirleIcon"></div>
          <div class="titleText">“频选”精华内容</div>
        </div>
        <div class="secondContentText">
          <div class="funcSubtitle">筛选优质内容 促进文化科普</div>
          <div class="describtion">
            -我们希望用户在多频社区里看到的每一份内容，都能让他们对电子音乐的理解加深一分；<br />
            -多频“频选”秉持精细严谨的原则，推荐电音圈内优质的音乐作品、媒体评析、文化科普、知识教育等内容；<br />
            -多频的世界里，优质用心有营养的内容，远比哗众取宠的流量更有分量。我们希望用“良币”推动国内电子音乐文化良性健康发展
          </div>
        </div>
      </div>
      <div class="functionImg" v-if="!isMobile">
        <img class="imgCut" src="@/assets/style/Group 74.png" alt="" />
      </div>
    </div>
    <!-- 第六个功能 -->
    <div class="functionItem imgleft">
      <div class="functionImg">
        <img class="imgCut" src="@/assets/style/Group 75.png" alt="" />
      </div>
      <div class="functionText">
        <div class="functionTitle">
          <div class="cirleIcon"></div>
          <div class="titleText">“频一频”互动挑战</div>
        </div>
        <div class="secondContentText">
          <div class="funcSubtitle">
            多种形式电音知识小挑战 看看别人都是怎么答的
          </div>
          <div class="describtion">
            -首页随心刷一刷，电音问答来比划！猜风格、识厂牌、辩艺人......看看你的电音知识储备，击败了多少DROPUCER！
          </div>
        </div>
      </div>
    </div>
    <!-- 第七个功能 -->
    <div class="functionItem imgRight">
            <div class="functionImg" v-if="isMobile">
        <img class="imgCut" src="@/assets/style/本体3.gif" alt="" />
      </div>
      <div class="functionText">
        <div class="functionTitle">
          <div class="cirleIcon"></div>
          <div class="titleText">趣味本体玩法</div>
        </div>
        <div class="secondContentText">
          <div class="funcSubtitle">个性调频头像框，修炼等级谁家强</div>
          <div class="describtion">
            -电音的世界由不同的频率构成，而多频中的每个本体都是独一无二的频率。从20~20000hz，从赤炎到冰蓝，个性频率头像框，用“频率”定义你的状态！<br />
            -开始一场从Intro到Drop的修炼，参与社区互动，你就将是万人敬仰的社区元老！
          </div>
        </div>
      </div>
      <div class="functionImg" v-if="!isMobile">
        <img class="imgCut" src="@/assets/style/本体3.gif" alt="" />
      </div>
    </div>
    <!-- 第八个功能 -->
    <div class="functionItem imgleft">
      <div class="functionImg">
        <img class="imgCut" src="@/assets/style/Group 76.png" alt="" />
      </div>
      <div class="functionText">
        <div class="functionTitle">
          <div class="cirleIcon"></div>
          <div class="titleText">一键搜索</div>
        </div>
        <div class="secondContentText">
          <div class="funcSubtitle">
            全局搜索开启，频段/百科/信号/发电者轻松定位
          </div>
          <div class="describtion">
            -可以快速查找频段（话题）/百科/信号内容，再也不用担心在社区里迷路啦
          </div>
        </div>
      </div>
    </div>
    <div class="fullFooter footerText">
      <div class="subtitle">为了电音</div>
      <div class="footerInfo">
        <div>广州和音文化科技有限公司</div>
        <div>微信公众号：DROPIN多频 | 邮箱：contact_us@hearinmusic.com</div>
        <div>广州市天河区五山路381号35号楼</div>
      </div>
      <div class="line" v-if="!isMobile"></div>
      <div class="fullRight">
        <div>© 广州和音文化科技有限公司, All Right Reserved.</div>
        <div class="fullRightItem">
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44010602009458"
            target="blank"
          >
            <div class="flex">
              <div class="footerImg">
                <img src="@/assets/style/备案图标.png" alt="" />
              </div>
              <div class="footerText">粤公网安备 44010602009458号</div>
            </div>
          </a>
        </div>
        <div class="fullRightItem">
          <a href="https://beian.miit.gov.cn/#/Integrated/index" target="blank"
            >粤ICP备 2021024499号</a
          >
        </div>
      </div>
    </div>
    <template>
      <el-backtop target=".home-wrapper" :bottom="100" :visibility-height="100">
        <div class="backTop">UP</div>
      </el-backtop>
    </template>
  </div>
</template>

<script>
import WebHeader from "@/components/WebHeader.vue";
export default {
  components: {
    WebHeader,
  },
  async created() {
    document.title = "功能介绍 - DROPIN多频";
  },
};
</script>

<style scoped>
.mainImg {
  margin-top: 60px;
}
.functionItem {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 0;
}
.functionText {
  width: 540px;
  margin: 0 40px;
}
.functionTitle {
  display: flex;
  align-items: center;
  font-size: 36px;
}
.funcSubtitle {
  font-size: 24px;
  color: var(--font-color);
}
.functionImg {
  height: 600px;
  overflow: hidden;
  margin: 0 40px;
}
.secondContentText {
  margin: 24px 0 0 34px;
}
.describtion {
  font-size: 18px;
}
.describtion {
  margin-top: 16px;
  line-height: 32px;
}
.functionItem.imgRight {
  /*background: #1f1f1f;*/
  background: var(--background-color-item);
}
.functionItem.imgLeft {
  background: var(--background-color);
}
.bannerImgCut {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.imgCut {
  width: auto;
  object-fit: cover;
}
/* 移动端兼容适配 */
.mobilePage .mainImg {
  margin-top: 50px;
}
.mobilePage .functionItem {
  display: block;
  padding: 18px;
}
.mobilePage .functionText {
  width: auto;
  margin: 0 16px;
}
.mobilePage .titleText {
  font-size: 20px;
}
.mobilePage .functionTitle {
  justify-content: center;
}
.mobilePage .funcSubtitle {
  font-size: 15px;
}
.mobilePage .describtion {
  font-size: 15px;
  line-height: 28px;
}
.mobilePage .secondContentText {
  margin: 10px 0 0 0;
  text-align: center;
}
.mobilePage .functionImg {
  height: 320px;
  text-align: center;
  margin: 10px 0;
}
.mobilePage .fullRight {
  flex-direction: column;
}
</style>
