<template>
  <div class="home-wrapper" :class="{mobilePage : isMobile}">
    <web-header :disabledScroll="true" />
    <div class="team">
      <div class="pageTitle">
        <div class="cirleIcon"></div>
        <div class="titleText">团队成员</div>
      </div>
      <div class="member">
        <div class="memberLine">
          <div class="memberItem">
            <div class="avatar">
              <img class="imgCut" src="@/assets/style/Shane@2x.png" alt="" />
            </div>
            <div class="memberInfo">
              <div class="name">SHANE FENG</div>
              <div class="position">
                创始人 / 不务正业的音乐人 / 自媒体创作者 / 猎奇主义者
              </div>
              <div class="purpleLine"></div>
              <div class="memberBrief">
                “愿意为了电子音乐燃烧生命的普通爱好者，Progressive/Glitch/Dubstep/Bass/Intelligent等各类风格深度中毒”
              </div>
            </div>
          </div>
          <div class="memberItem">
            <div class="avatar">
              <img class="imgCut" src="@/assets/style/zero 1@2x.png" alt="" />
            </div>
            <div class="memberInfo">
              <div class="name">ZERO ZHONG</div>
              <div class="position">
                联合创始人 / 嘻哈音乐人 / 自由投资者 / 全球酒店试睡员
              </div>
              <div class="purpleLine"></div>
              <div class="memberBrief">
                “耳听电音、嘴吐说唱、眼盯市场、手操游戏的混合体，喜好Hip-Hop/Future
                Bass/EDM/Chill Out等音乐风格的迷途小听众”
              </div>
            </div>
          </div>
        </div>
        <div class="memberLine">
          <div class="memberItem">
            <div class="avatar">
              <img class="imgCut" src="@/assets/style/Wendy@2x.png" alt="" />
            </div>
            <div class="memberInfo">
              <div class="name">WENDY DENG</div>
              <div class="position">
                联合创始人 / 专业是化学的互联网产品民工 / 多频法王
              </div>
              <div class="purpleLine"></div>
              <div class="memberBrief">
                “无欲无求的普通人，Kawaii Future Bass/Jazz Hiphop/Chillwave”
              </div>
            </div>
          </div>
          <div class="memberItem">
            <div class="avatar">
              <img class="imgCut" src="@/assets/style/Winnie@2x.png" alt="" />
            </div>
            <div class="memberInfo">
              <div class="name">WINNIE XIE</div>
              <div class="position">联合创始人 / 设计狮 / 互联网冲浪爱好者</div>
              <div class="purpleLine"></div>
              <div class="memberBrief">
                “当地一名比较优秀的设计师，House入门鉴赏员”
              </div>
            </div>
          </div>
        </div>
        <div class="memberLine">
          <div class="memberItem">
            <div class="avatar">
              <img class="imgCut" src="@/assets/style/Shaw@2x.png" alt="" />
            </div>
            <div class="memberInfo">
              <div class="name">SHAW XIAO</div>
              <div class="position">
                联合创始人 / 互联网时代必备打击乐器掌控者 / 二次元爱好者
              </div>
              <div class="purpleLine"></div>
              <div class="memberBrief">
                “飞驰在秃顶赛道的年轻人，听到Future
                Bass/Dubstep/EDM等时打字速度会加快”
              </div>
            </div>
          </div>
          <div class="memberItem">
            <div class="avatar">
              <img class="imgCut" src="@/assets/style/Selina@2x.png" alt="" />
            </div>
            <div class="memberInfo">
              <div class="name">SELINA SONG</div>
              <div class="position">
                联合创始人 / 口是心非的毒舌评论家 / 开朗的悲观主义者
              </div>
              <div class="purpleLine"></div>
              <div class="memberBrief">
                “刚迈入社会就想着退休的重度选择困难症患者，听Chillout/Nu-soul/Nu-Jazz或按摩有几率舒缓”
              </div>
            </div>
          </div>
        </div>
        <div class="memberLine">
          <div class="memberItem">
            <div class="avatar">
              <img class="imgCut" src="@/assets/style/jacobWei.jpeg" alt="" />
            </div>
            <div class="memberInfo">
              <div class="name">Jacob Wei</div>
              <div class="position">
                内容运营/资深编辑
              </div>
              <div class="purpleLine"></div>
              <div class="memberBrief">
                情绪化电子乐制作人，Au5的粉丝头子，喜欢在听trancestep时幻想宇宙
              </div>
            </div>
          </div>
          <div class="memberItem">
            <div class="avatar">
              <img class="imgCut" src="@/assets/style/pudding.jpeg" alt="" />
            </div>
            <div class="memberInfo">
              <div class="name">Pudding</div>
              <div class="position">
                内容运营/资深编辑
              </div>
              <div class="purpleLine"></div>
              <div class="memberBrief">
                杂食听众，不知名的三线制作人，没事会写点House玩玩
              </div>
            </div>
          </div>
        </div>
          <div class="memberItem oddMember">
            <div class="avatar">
              <img class="imgCut" src="@/assets/style/cozu.jpeg" alt="" />
            </div>
            <div class="memberInfo">
              <div class="name">COZU</div>
              <div class="position">
                内容运营/编辑
              </div>
              <div class="purpleLine"></div>
              <div class="memberBrief">
                钟爱House但啥风格都想做的制作人，偶尔还会打打碟
              </div>
            </div>
          </div>
      </div>
    </div>
    <div class="story">
      <div class="pageTitle">
        <div class="cirleIcon"></div>
        <div class="titleText">产品故事</div>
      </div>
      <div class="storyDet">
        <div class="subtitle">01 前生</div>
        <div class="aboutP">
          我是电子音乐爱好者Shane。从一名普通的听众，到业余的制作爱好者，到电台电音栏目的编辑和DJ，到电音自媒体的博主，再到如今放下一切投入电音社区的搭建。好像从我喜欢上电子音乐的那一刻起，人生中无数重要的决定和选择，都不由自主地向它倾斜。
        </div>
        <div class="aboutP">
          事实上，和绝大多数国内的电音音乐人、自媒体或行业内人士一样，我们通过音乐直接获得的收入可以说是微乎其微。大家都在以各自不同的方式，支撑自己在追逐梦想的道路上一瘸一拐地奔跑，“为爱发电”已经成为这个圈子里令人备受鼓舞又倍感无奈的真实写照。比收入微薄更令人沮丧的是孤独，很多人都曾和我吐槽身边没有可以交流电音的人，找不到有归属感的组织。<b
            >我们是如此的渴望共鸣，却往往只得顾影自怜。</b
          >
        </div>
        <div class="aboutP">
          <img src="@/assets/style/story1 1.png" alt="" />
        </div>
        <div class="aboutP">
          万幸，回报上的匮乏从未阻碍电子音乐爱好者们的热情，从音乐人到听众，越来越多人正前赴后继的加入到这股属于年轻和未来的音乐浪潮中。我们身边喜爱电音的人的数量其实已远超想象，只是我们还没有清晰的意识到而已。
        </div>
        <div class="aboutP">
          一直以来，我都希望尽己所能去传播和推广电音文化。我也足够幸运，一路上结识了无数热爱电音的伙伴，其中有颇有名气的大佬制作人，也有刚接触电音的懵懂学生。<b
            >正是大家对电子音乐文化共同的认可和热爱，让我发现自己并不是在独行，也开始相信电音在中国未来的发展一定是充满希望的。</b
          >
        </div>
        <div class="aboutP">
          <img src="@/assets/style/story2 1.png" alt="" />
        </div>
        <div class="aboutP">
          正因如此，我想要将这份鼓舞和信心传递给更多的爱好者，让他们可以更加坚定的走下去，从而为中国电子音乐行业注入源源生命力。我相信这是每一个真正热爱电子音乐文化的人的使命、责任和期望，为此我们更无比需要坚定团结在一起，守护和传播我们共同的信仰。这一刻我知道，<b
            >中国电子音乐的圈子，需要一个家，一个真正完全属于我们的家园，可以让千千万和我们一样的人汇聚一堂，共同承载这个简单而厚重的梦想。</b
          >
        </div>
        <div class="subtitle">02 萌芽</div>
        <div class="aboutP">
          2020年秋，几位核心成员陆续放下原有的工作和事务，一群怀揣梦想，天不怕地不怕的年轻人就这样组成了和音团队（注册为“广州和音文化科技有限公司”）。我们不是呼风唤雨的资本大鳄，但有着满腔赤诚热血和坚定信仰。摆在我们面前的第一个问题，就是这个电子音乐的家园应该如何命名？经过长时间的策划和打磨，“DROPIN多频”的概念最终脱颖而出。老实说，选择“DROPIN多频”作为APP名字和核心概念是个无比简单的决定，六个英文字母，两个汉字，包含了所有我们希望分享给用户的想法：
        </div>
        <div class="aboutP aboutLogo">
          <img class="imgCut" src="@/assets/style/紫底横_logo_name.png" alt="" />
        </div>
        <div class="aboutP">
          <b
            >DROPIN，源自电子音乐舞曲中的DROP，它代表电子音乐艺术中最精华的部分</b
          >，是让人为之沉醉，文化得以发展的关键所在。我们希望优质的电子音乐内容正在并定将会在此深入人心。
        </div>
        <div class="aboutP">
          <b>多频，源自声音的本质——由不同频率的声波而组成</b
          >。多种多样的频率组成了“多频”的世界，这里的每首音乐、每个观点、每个用户，都是独一无二且同样重要的频率。
        </div>
        <div class="aboutP">
          这毫无疑问也是完美契合电子音乐文化的概念。围绕这个概念，我们对很多功能进行了个性化设计，如将帖子变成“信号”，发送变成“DROP（投放）”，话题变成“频段”，点赞变成“点头”，用户称为“DROPUCER”等......还有很多的小惊喜设定，留待大家去APP里体验发现。
        </div>
        <div class="aboutP">
          产品的口号，我们定为<b>“为了电音”</b>。原因很简单，有什么是1年，10年，甚至百年后我们都不会改变的？就是这简单的四个字，所有的这一切，以及未来将做的一切，都是为了电子音乐。我们希望寻找和集结的，也正是秉持着同样坚定信念的人
        </div>
        <div class="aboutP">
          就这样，<b>为了电音的梦想播撒下的种子，以DROPIN多频为起点开始萌芽了</b>.
        </div>
        <div class="subtitle">03 破茧</div>
        <div class="aboutP">
          软件开发的过程是复杂且艰难的。在团队的不懈努力下，电音社区的小程序抢先测试端在2021年年初正式上线，而“DROPIN多频”APP内测版本也终于在同年4月破茧而出，迎来它的第一波忠实用户。<b
            >2021年8月，DROPIN多频1.0公测版本发布，我们也终于可以与广大电子音乐爱好者们正式公开的见面了.</b
          >
        </div>
        <div class="aboutP">
          <img src="@/assets/style/APP界面slogan_画板 1 1@2x.png" alt="" />
        </div>
        <div class="aboutP">
          同时，稚嫩的我们仍有很多缺陷和不足。为此，我们在APP的“设置”-“反馈”中专门建立了反馈渠道，并同时设立了官方公众号、客服助手、核心用户群等渠道，以便DROPUCER及时与我们取得联系。<b>各位在APP使用过程中有任何的想法、建议、疑问或不满，都欢迎随时与我们沟通，你们的意见将是我们改进的重要参考，也会让我们知道怎样的产品可以更好地服务国内电子音乐的爱好者群体。</b>再次感谢各位的理解和耐心，愿意陪伴我们从起点开始，踏上一段未知的征程。
        </div>
        <div class="subtitle">04 未来</div>
        <div class="aboutP">
          终于，DROPIN多频，这个纯粹为了电子音乐爱好者而建立的家园将要敞开大门。
        </div>
        <div class="aboutP">
          或许现在我们还很渺小，但我们希望它能一步一步承载起万千爱好者们对电子音乐无尽的热情。
        </div>
        <div class="aboutP">
          昨天，国内的电音爱好者们大多还像找不到家的孩子，失散流离于天涯海角，孤芳自赏中寻觅共鸣。明天，我们将共同建造一个家园，一个专属于电子音乐文化的家园，见证电子音乐的种子在这片充满生机的土壤中成长为参天大树。
        </div>
        <div class="aboutP">
          <b>未来已来！DROPIN多频诚挚的邀请各位，一起共筑这个家园！</b>
        </div>
      </div>
    </div>
    <div class="fullFooter footerText">
      <div class="subtitle">为了电音</div>
      <div class="footerInfo">
        <div>广州和音文化科技有限公司</div>
        <div>微信公众号：DROPIN多频 | 邮箱：contact_us@hearinmusic.com</div>
        <div>广州市天河区五山路381号35号楼</div>
      </div>
      <div class="line" v-if="!isMobile"></div>
      <div class="fullRight">
      <div>© 广州和音文化科技有限公司, All Right Reserved.</div>
      <div class="fullRightItem">
        <a
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44010602009458"
          target="blank"
        >
          <div class="flex">
            <div class="footerImg">
              <img src="@/assets/style/备案图标.png" alt="" />
            </div>
            <div class="footerText">粤公网安备 44010602009458号</div>
          </div>
        </a>
      </div>
      <div class="fullRightItem">
        <a href="https://beian.miit.gov.cn/#/Integrated/index" target="blank"
          >粤ICP备 2021024499号</a
        >
      </div>
      </div>
    </div>
    <template>
      <el-backtop target=".home-wrapper" :bottom="100" :visibility-height="100">
        <div class="backTop">UP</div>
      </el-backtop>
    </template>
  </div>
</template>

<script>
import WebHeader from "@/components/WebHeader.vue";
export default {
  components: {
    WebHeader,
  },
  async created() {
    document.title = "关于多频 - DROPIN多频";
  },
};
</script>

<style>
.team {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 147px;
}
.cirleIcon {
  width: 14px;
  height: 14px;
  border: 5px solid #9c5cf7;
  border-radius: 50%;
}
.story {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 80px 0;
  flex-direction: column;
  padding: 0 120px;
}
.titleText {
  font-size: 36px;
  font-weight: 800;
  margin-left: 10px;
}
.pageTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 36px;
}
.memberLine {
  display: flex;
  align-items: center;
  justify-content: center;
}
.avatar {
  width: 155px;
  height: 155px;
  border-radius: 4px;
  overflow: hidden;
}
.memberItem {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px;
}
.memberInfo {
  width: 415px;
  margin-left: 20px;
}
.memberLine {
  margin-bottom: 86px;
}
.name {
  font-size: 18px;
  font-weight: 700;
}
.position {
  font-size: 15px;
  color: var(--color-80);
  line-height: 26px;
}
.memberBrief {
  font-weight: 100;
  font-size: 14px;
  line-height: 26px;
  width: 390px;
}
.purpleLine {
  width: 28px;
  height: 4px;
  background: linear-gradient(90deg, #9c5cf7, #7123e5 100%);
  border-radius: 2px;
  margin: 5px 0 18px 0;
}
.subtitle {
  font-size: 24px;
  font-weight: 400;
  margin: 36px 0;
}
.aboutP {
  font-size: 15px;
  line-height: 35px;
  margin-bottom: 30px;
  width: 1150px;
}
.storyDet {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.fullFooter {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /*background: #1f1f1f;*/
  background: var(--background-color-item);
  height: 250px;
  padding-bottom: 24px;
}
.aboutP img {
  width: 100%;
  font-size: 15px;
  color: #d9d9d9;
}
.line {
  height: 1px;
  background: #555555;
  width: 1150px;
  margin: 20px 0;
}
.footerInfo {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.fullRight {
    display: flex;
  align-items: center;
  justify-content: center;
}
.fullRightItem {
    margin: 0 60px;
}
.fullRightItem a {
    color: var(--color-50);
}
.member {
    margin-top: 36px;
}
.aboutLogo {
    width: 536px;
}
</style>

<style scoped>
.mobilePage .fullRight {
  flex-direction: column;
}
.mobilePage .memberLine {
  display: block;
  margin: 0;
}
.mobilePage .memberItem {
  margin: 36px 16px;
  align-items: unset;
}
.mobilePage .memberInfo {
  width: auto;
  margin-left: 0;
  word-break: break-all;
}
.mobilePage .memberBrief {
  width: auto;
}
.mobilePage .avatar {
  flex-shrink: 0;
  width: 100px;
  height: 100px;
  margin-right: 20px;
}
.mobilePage .aboutP {
  width: 100%;
}
.mobilePage .story {
  padding: 0 16px;
}
.mobilePage .team {
  margin-top: 100px;
}
.mobilePage .pageTitle {
  margin-bottom: 10px;
}
.oddMember.memberItem {
  margin-right: 650px;
}
.mobilePage .oddMember.memberItem {
  margin: 36px 16px;
}
</style>
